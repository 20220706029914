body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: "Lato", "Helvetica Neue", helvetica, sans-serif;
}

.masthead {
  text-align: center;
}

.masthead .logo {
  margin: 0 auto 1em;
  max-width: 200px;
}

.masthead h1 {
  font-size: 50px;
  font-weight: 900;
  margin: 0 auto 0.8em;
  color: #0f224d;
}

.app-list-bounds {
  margin: 0 auto;
  max-width: 600px;
  padding: 100px 40px;
}

.app-list li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-launch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 12px;
  padding: 16px;
  border: 1px solid #f1f1f1;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 16px 8px -8px rgba(55, 74, 93, 0.03);
  text-decoration: none;
  transition: 0.2s ease all;
}
.app-launch:hover {
  border-color: #f8f8f8;
  box-shadow: 0 11px 18px -5px rgba(48, 106, 181, 0.13);
}

.app-launch:hover .btn {
  box-shadow: 0 4px 6px -2px rgba(62, 116, 185, 0.5);
}

.app-launch.account-settings {
  align-items: center;
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  padding: 8px 16px;
}
.app-launch.account-settings:hover {
  background: #fafafa;
}

.app-launch.account-settings p {
  color: #737373;
  font-size: 14px;
  line-height: 28px;
}

.app-list .app-logo {
  flex: 2;
}

.app-logo img,
.app-logo svg {
  height: 32px;
}

.app-list .btn {
  font-size: 0.85em;
  color: #fff;
  background: linear-gradient(90deg, #3b78ff, #0855ff) no-repeat;
  border-radius: 4px;
  padding: 8px 10px;
  line-height: 1;
  display: inline-block;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  transition: 0.1s ease all;
}

@media only screen and (max-width: 600px) {
  .app-list-bounds {
    padding: 80px 20px;
  }
  .masthead h1 {
    font-size: 40px;
  }
}
